<template>
    <div>
        <input class="form-control mb-3"
               type="email"
               v-model="email"
               name="email"
               placeholder="Introdu adresa de e-mail">
        <button class="btn btn-primary w-100 mb-2"
                type="button"
                :disabled="loading"
                @click="subscribe">Abonează-te</button>
    </div>
</template>

<script>
    export default {
        data()
        {
            return {
                email: '',
                error: '',
                message: '',
                loading: false,
            }
        },

        methods:
        {
            subscribe()
            {
                if(this.email !== '')
                {
                    this.loading = true;
                    this.error = '';
                    this.message = '';
                    axios.post('/newsletter/subscribe', {email: this.email})
                        .then(data =>
                        {
                            this.message = data.data.message;
                            this.email = '';
                            toastr['success'](this.message, '', {timeOut: 5000});
                            this.loading = false;
                        })
                        .catch(error =>
                        {
                            this.error = error.response.data.errors.email[0];
                            toastr['error'](this.error, '', {timeOut: 5000});
                            this.loading = false;
                        })
                }
                else
                {
                    this.error = 'Completează adresa de e-mail';
                    toastr['error'](this.error, '', {timeOut: 5000});
                    this.loading = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>
