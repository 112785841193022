window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {
    console.log(e);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

// Menu
require('jquery-hoverintent');
require('superfish');

// Toastr notifications
window.toastr = require('toastr');
toastr.options.timeOut = 0;
toastr.options.extendedTimeOut = 0;
// Slider //
require('jquery-ui/ui/widgets/slider');

// Slick slider
require('slick-carousel');

// Fancybox
require('@fancyapps/fancybox');

if($('#newsletter').length > 0)
{
    window.Vue = require('vue');
    Vue.component('newsletter', require('./components/NewsletterComponent.vue').default);
    const app = new Vue({
        el: '#newsletter'
    });
}